export default function header() {
    $(document).ready(() => {
        $('.js--header__toggle').on('click', function () {
            $(this).closest('.js--header').find('.js--header__nav').toggleClass('active');
            $('html, body').toggleClass('page--overflow');
        });
    });

    $(window).scroll(function() {
        if (window.matchMedia('(min-width: 768px)').matches) {
            if ($(this).scrollTop() > 30) {
                $('.js--header').addClass('header--scroll');
            } else {
                $('.js--header').removeClass('header--scroll');
            }
        }
    });
}
