// Core
import $ from 'jquery';
window.$ = $;
window.jQuery = $;

// Imports
import 'slick-carousel/slick/slick.min';

import header from './components/blocks/header/header';
import slider from './components/blocks/slider/slider';

// Loading
header();
slider();