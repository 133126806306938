export default function slider() {
    const sliderCore = {
        setSlider() {
            $('.js--slider').slick({
                slidesToShow: 1,
                slidesToScroll: 1,
                infinite: true,
                dots: false,
                prevArrow: $('.slider .slick-prev'),
                nextArrow: $('.slider .slick-next'),
                mobileFirst: true,
                responsive: [
                    {
                        breakpoint: 767,
                        settings: {
                            slidesToShow: 2,
                            slidesToScroll: 1,
                            centerMode: true,
                        },
                    },
                    {
                        breakpoint: 991,
                        settings: {
                            slidesToShow: 3,
                            slidesToScroll: 1,
                        },
                    },
                ],
            });
        },
    };
    $(document).ready(() => {
        sliderCore.setSlider();
    });
}